import "bootstrap/dist/css/bootstrap.css";
import "modern-normalize/modern-normalize.css";
import 'intl-tel-input/build/css/intlTelInput.min.css';
import 'animate.css';

import "./utils";
import "./swiper";
import "./loadMore";
import "./modal";
import "./clicked";



import "./refs";


import "../css/main.scss";
