import refs from "./refs";

const { loadMoreButton, } = refs;

const handleButtonClick = () => {

    loadMoreButton.textContent === "Приховати" ?  loadMoreButton.textContent = `Завантажити ще ${loadMoreButton.length}` :   loadMoreButton.textContent = `Приховати`;
};

const showOffers = function() {
    const $this = $(this); // define $this inside the function

    // Assuming offersContent is an element with a class of 'two-content'
    const offersContent = $(".two-content");

    if ($this.attr("data-visibility") === 'hidden') {
        $this.attr("data-visibility", "visible");
        $this.text("Приховати");
        let contentHeight = offersContent.prop('scrollHeight');
        offersContent.css("max-height", contentHeight + "px");
    } else {
        $this.attr("data-visibility", "hidden");
        $this.text("Показати більше пропозицій");
        offersContent.css("max-height", 0);
    }
};

$(".load-more-button").on('click', function(){
    $('.list-wrapper ').slideToggle()
})

const showAllOffersButton = $("#load-more-button");

showAllOffersButton.on("click", () => {
    handleButtonClick();
    showOffers.call(showAllOffersButton);
});



