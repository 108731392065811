
import {WOW} from "wowjs/dist/wow.min";
import refs from "./refs";

const {bodyEl, heroButton ,closeButton , socAction} = refs;

document.addEventListener("DOMContentLoaded", function () {
    const currentUrl = window.location.href; // Отримання поточного URL
    document.querySelector('[name="page_referer"]').value = currentUrl; // Додавання URL до полів CF7
});


let currentBackdrop = null;



export const showBackdrop = (backdrop, hideOnResize = false) => {
    if (!backdrop) {
        return;
    }
    disableBodyScroll();

    backdrop.removeClass("is-hidden");
    backdrop.on("click", handleBackdropClick);
    $(window).on("keydown", handleKeyDown);
    currentBackdrop = backdrop;

    if (hideOnResize) {
        $(window).on("resize", throttledHandleResize);
    }
};

export const hideBackdrop = (backdrop) => {
    if (!backdrop) {
        return;
    }

    enableBodyScroll();

    backdrop.addClass("is-hidden");
    backdrop.removeClass("click", handleBackdropClick);
    $(window).off("keydown", handleKeyDown);
    $(window).off("resize", throttledHandleResize);

    currentBackdrop = null;
};

function handleBackdropClick(e) {
    if (e.target === e.currentTarget) {
        hideBackdrop(currentBackdrop);
    }
}

function handleKeyDown(e) {
    if (e.key === "Escape") {
        hideBackdrop(currentBackdrop);
    }
}

function handleResize() {
    const {innerWidth} = window;

    if (innerWidth >= 768) {
        hideBackdrop(currentBackdrop);
    }
}



$(window).on('scroll', function() {
    // Отримуємо розміри секції singleAboutSection та висоту вікна браузера
    const singleAboutSection = $("#single-about");
    const rect = singleAboutSection[0].getBoundingClientRect();
    const windowHeight = $(window).height();
    const windowMidPoint = windowHeight / 2; // середина висоти вікна

    // Якщо верхній край секції 'single-about' знаходиться вище від нижньої межі вікна браузера
    // і верхній край вікна браузера вже починає заходити на середину секції, то додаємо клас 'invite'
    if (rect.top < windowHeight && rect.top > -windowMidPoint) {
        socAction.addClass('invite');
    } else {
        socAction.removeClass('invite');
    }
});

// Додаємо обробник події "click" до кнопки closeButton
closeButton.on('click', function () {
    // Додаємо клас '--close' елементу socAction
    socAction.addClass('--close');
});


export function enableBodyScroll() {
    bodyEl.css("overflow-y", "scroll");
}

export function disableBodyScroll() {
    bodyEl.css("overflow-y", "hidden");
}





$("document").ready(function () {
    bodyEl.css("visibility", "visible");

    new WOW().init();
});


