const refs = {
    bodyEl: $("body"),
    heroButton: $(".hero-button"),
    loadMoreButton: $('#load-more-button'),

    menuButton: $(".menu-button"),
    menuBackdrop: $("#menu"),
    menuNavLinks: $("#menu .nav-list a"),

    appButtons: $(".app-button-js"),
    hideButton: $(".hide-app-button"),
    appBackdrop: $("#appointment"),
    appHideButton: $(".appointment-button"),

    closeButton: $(".button-close"),
    socAction: $(".single-soc"),
};

export default refs;
