const handleButtonClick = (e) => {
    const $this = $(e.target);
    const dataTooth = $this.data('tooth');

    // Зміна класу кнопки
    $this.toggleClass('is-active');

    // Підрахунок кількості обраних зубів
    const selectedTeethCount = $('.jaw button.is-active').length;
    $('.counter-discount').css('opacity', selectedTeethCount > 0 ? 1 : 0);

    // Оновлення вмісту параграфу з класом "counter"
    $('.counter-teeth').text(selectedTeethCount);
}

$('.jaw').on('click', 'button', handleButtonClick);

